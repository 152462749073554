import React from "react";

import { Link } from "gatsby";

import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/ViewList";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // background: "linear-gradient(to right,  #4E46AA, #5B72FF)",
    background: theme.palette.common.black,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  names: {
    marginTop: "-20px",
    color: "#212121",
  },
  BLM: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: "1.5em",
    width: "100vw",
    display: "block",
  },
  hashtag: {
    color: theme.palette.common.white,
  },
}));

// const MenuBar = () => {
//   return (
//     <IconButton
//       color="inherit"
//       aria-label="Open drawer"
//       onClick={handleDrawerOpen}
//       edge="start"
//       className={clsx(classes.menuButton, open && classes.hide)}
//     >
//       <MenuIcon />
//     </IconButton>
//   )
// }

declare global {
  namespace JSX {
    interface IntrinsicElements {
      marquee: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
const Header = () => {
  const classes = useStyles();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        {/* <Toolbar>
          <marquee className={classes.BLM}>
            <p>
              <span className={classes.hashtag}>#BlackLivesMatter.</span>-
              Please consider a donation to one of many great causes, such as{" "}
              <Link to="https://blacklivesmatter.com/" target="_blank">
                Black LivesMatter
              </Link>
              <Link to="https://www.naacpldf.org/" target="_blank">
                The NAACP Legal Defense and Educational Fund
              </Link>
              <Link to="https://www.reclaimtheblock.org/" target="_blank">
                Reclaim the Block
              </Link>
              <Link to="https://eji.org/" target="_blank">
                The Equal Justice Initiative
              </Link>
              <Link to="https://www.naacpldf.org/" target="_blank">
                We The Protestors
              </Link>
              and the
              <Link to="https://www.wetheprotesters.org/" target="_blank">
                George Gloyd Memorial Fund
              </Link>
            </p>
          </marquee>
        </Toolbar> */}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
          </Link>
          <Link to="/components">
            <ListItem button>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText>Components</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </div>
  );
};

export default Header;

import React from "react";
import { Grid, Button, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Customers } from "./customers";
import { ContactForm } from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  cta: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  ctaText: {
    fontWeight: 900,
  },
  spacer: {
    [theme.breakpoints.down("sm")]: {
      height: "5vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "20vh",
    },
  },
}));

export const ContactUs: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={10}>
        <div className={classes.spacer} />
        <Typography variant="h3">
          email:{" "}
          <Link to="mailto:william@fullsignal.co">William@fullsignal.co</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

/*
 <Grid item xs={10}>
        <Customers />
      </Grid> */
